<template>
	<div>
		<div v-for="notification in notifications" :key="notification.ID">
			<component :is="resolveNotification(notification.type)" :notification="notification" />
		</div>
	</div>
</template>

<script setup>
const CompareBox = resolveComponent('CompareBox');
const NewsletterBox = resolveComponent('NewsletterBox');
const Notes = resolveComponent('Notes');
const Popup = resolveComponent('Popup');
const SpecialOffersBox = resolveComponent('SpecialOffersBox');
const ReviewBox = resolveComponent('ReviewBox');

const resolveNotification = (type) => {
	switch (type) {
		case 'compare-box':
			return CompareBox;
		case 'newsletter-box':
			return NewsletterBox;
		case 'notes':
			return Notes;
		case 'popup':
			return Popup;
		case 'special-offers-box':
			return SpecialOffersBox;
		case 'review-box':
			return ReviewBox;
		default:
			return SpecialOffersBox;
	}
};

defineProps({
	notifications: { type: Array, default: () => [] },
});
</script>
