<template>
	<div
		class="notification notes"
		:class="[
			{
				mobile: notification.showOnMobile,
				tablet: notification.showOnTablet,
				desktop: notification.showOnDesktop,
			},
			position,
		]"
	>
		<div v-for="(note, index) in notification.notes" :key="index" class="note-wrapper">
			<note :id="notification.ID" :note="note" :trigger="notification.trigger" :position="position" />
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const position = props.notification.conversionPosition;
</script>

<style lang="scss" scoped>
.notes {
	max-width: 430px;
	width: 100%;
	transition: all 0.7s;
}

@media (max-width: 480px) {
	.notes:not(.mobile) {
		display: none !important;
	}

	.notes {
		left: 10px;
		max-width: calc(100% - 20px);
	}
}

@media (max-width: 801px) {
	.notes:not(.tablet) {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	.notes:not(.desktop) {
		display: none !important;
	}
}
</style>
