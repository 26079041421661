<template>
	<transition :name="`notification-${position}`">
		<div
			v-show="show"
			class="notification compare-box"
			:class="[
				{
					mobile: notification.showOnMobile,
					tablet: notification.showOnTablet,
					desktop: notification.showOnDesktop,
				},
				position,
			]"
		>
			<div class="box-toggle">
				<h4>{{ notification.header }}</h4>
				<div class="circle" @click="() => (show = false)">
					<font-awesome-icon class="icon" icon="fas fa-xmark" size="1x" />
				</div>
			</div>
			<div class="body">
				<div class="compare-container">
					<div />
					<div class="logo">{{ hotelName }}</div>
					<div>
						<img src="/review-logo-bookingcom.png" />
					</div>

					<div class="bg-row-light">
						<span>{{ notification.benefits[0].content }}</span>
					</div>
					<div class="bg-row-light">
						<font-awesome-icon class="icon" icon="fas fa-check" />
					</div>
					<div class="bg-row-light">
						<font-awesome-icon class="icon" icon="fas fa-xmark" />
					</div>

					<div class="bg-row-dark">
						<span>{{ notification.benefits[1].content }}</span>
					</div>
					<div class="bg-row-dark">
						<font-awesome-icon class="icon" icon="fas fa-check" />
					</div>
					<div class="bg-row-dark">
						<font-awesome-icon class="icon" icon="fas fa-xmark" />
					</div>

					<div class="bg-row-light">
						<span>{{ notification.benefits[2].content }}</span>
					</div>
					<div class="bg-row-light">
						<font-awesome-icon class="icon" icon="fas fa-check" />
					</div>
					<div class="bg-row-light">
						<font-awesome-icon class="icon" icon="fas fa-xmark" />
					</div>

					<div class="bg-green" />
				</div>
				<a v-if="notification.button" target="_blank" :href="notification.link" class="button button-compact">
					{{ notification.button }}
				</a>
			</div>
		</div>
	</transition>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const show = ref(true);
const position = props.notification.conversionPosition;
const hotelName = useState('hotelName');

onMounted(async () => {
	if (!window.sessionStorage || !props.notification) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.notification.ID}`)) {
		return;
	}

	const showNotification = await getTrigger(props.notification.trigger);
	show.value = await showNotification(props.notification.ID);

	window.sessionStorage.setItem(`displayed-notification-${props.notification.ID}`, 'true');
});
</script>

<style lang="scss" scoped>
.logo {
	color: #222;
	font-size: 22px;
}

.compare-box {
	max-width: 490px;
	transition: all 0.3s ease-in;
	box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);

	.box-toggle {
		background: var(--dark-background-color);

		.circle .icon {
			cursor: pointer;
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: var(--primary-background-color);
			height: 13px;
			vertical-align: middle;
			width: 13px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px;
		}

		h4 {
			color: #fff;
			letter-spacing: 0;
			font-size: 24px;
			padding: 15px 20px;
		}
	}

	.body {
		background: var(--primary-background-color);
		padding: 30px;
		text-align: center;

		.button {
			margin: 30px 0 0;
			display: block;
		}

		.compare-container {
			display: grid;
			position: relative;
			grid-template-columns: 1.5fr 1fr 1fr;
			grid-template-rows: 1fr 1fr 1fr 1fr;
			text-align: left;

			> div {
				display: flex;
				align-items: center;
				padding: 16px 20px;
				justify-content: center;
				font-size: 18px;

				&:nth-child(3n + 4) {
					justify-content: revert;
				}
			}

			.bg-row-light {
				background-color: #fafafa;
			}

			.bg-row-dark {
				background-color: #f1f1f1;
			}

			.bg-green {
				grid-column: 2/2;
				grid-row: 1/5;
				background-color: #98ff9826;
				z-index: 3;
				width: 100%;
				border: 2px solid #bcffbc;
				position: absolute;
				height: 100%;
				left: 0;
				top: 0;
			}

			.icon {
				font-size: 21px;

				&.fa-check {
					color: #00d900;
				}

				&.fa-xmark {
					color: #e30000;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.compare-box:not(.mobile) {
		display: none !important;
	}

	.compare-box {
		left: 10px;
		max-width: calc(100% - 20px);

		.body .compare-container {
			.logo {
				font-size: 17px;
			}

			.icon {
				font-size: 18px;
			}

			> div {
				padding: 12px;
			}
		}
	}
}

@media (max-width: 801px) {
	.compare-box:not(.tablet) {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	.compare-box:not(.desktop) {
		display: none !important;
	}
}
</style>
